<div class="z-10 flex flex-row overflow-hidden">
	<ng-content select="[sidemenu]"></ng-content>
	<div class="flex flex-col flex-1 overflow-hidden">
		<div
			class="z-10 p-2 px-8 bg-white shadow-lg pointer-events-none shadow-yuno-blue-gray-600/10">
			<ng-content select="[nav]"></ng-content>
		</div>
		<div
			class="flex items-start justify-center flex-1 w-full h-full overflow-x-hidden overflow-y-auto container-fixed-w">
			@if ($containerType(); as type) {
				<div
					class="w-full"
					[ngClass]="
						type === 'data' ? containerWidth + ' h-auto px-4 py-14' : 'h-full p-0'
					">
					<ng-content select="[view]"></ng-content>
				</div>
			}
		</div>
	</div>
</div>
