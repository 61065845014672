import { Component, inject } from '@angular/core';

import { MainUiService } from '@yuno/admin/core';

@Component({
    selector: 'yuno-admin-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss'],
    standalone: false
})
export class MainLayoutComponent {
	private readonly service = inject(MainUiService);
	$containerType = this.service.containerType;
	containerWidth = this.service.containerWidth;
}
